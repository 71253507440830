.quote-version-select-item {
  padding: 6px 45px 6px 48px;
  position: relative;

  &__label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__version {
    font-weight: 500;
    &.selected {
      font-weight: 700;
    }
  }

  &__selected-icon {
    position: absolute;
    left: 18px;
  }
}
