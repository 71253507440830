.quote-item-row {
  $root: &;
  vertical-align: top;

  &__hidden {
    padding: 0;
  }

  &:hover {
    #{$root}__actions {
      display: flex;
    }
  }

  &__cell {
    white-space: nowrap;

    &__price,
    &__net-price,
    &__sub-total {
      min-width: 120px;
      max-width: 120px;
      overflow: auto;

      > p {
        overflow: auto;
      }
    }

    &__quantity,
    &__order {
      width: 80px;

      > div > div > div {
        margin: 0;

        button {
          padding: 2px;
          margin: 0 2px;
        }
      }
    }

    &__description-cell {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  &__order {
    margin-top: 6px;
  }
}
