.quote-table {
  position: relative;

  &__container {
    background-color: white;
    border-radius: 4px;
  }

  &__header-cell {
    width: auto;
    white-space: nowrap;
  }

  @media print {
    &__head {
      display: table-row-group;
    }
  }
}
