@import 'mixins/transitions';
@import 'variables';

.side-nav {
  $root: &;
  position: sticky;
  top: 0;
  position: relative;
  width: min-content;
  height: calc(100vh - var(--app-header-height));

  &__drawer-container {
    height: 100%;
  }

  &.open {
    &:hover {
      #{$root}__toggle {
        opacity: 1;
        @include easingSharp(opacity, $enteringScreen);
      }
    }

    #{$root}__toggle {
      opacity: 0;
      @include easeInOut(opacity, $leavingScreen);
    }

    #{$root}__drawer {
      width: 320px;
      @include easeInOut(width, $leavingScreen);
    }

    #{$root}__drawer-content {
      opacity: 1;
      @include easeInOut(opacity, $leavingScreen);
    }

    #{$root}__toggle-icon {
      transform: rotateZ(180deg);
    }
  }

  &__toggle-icon {
    transition: all 0.4s ease;
  }

  &__drawer {
    @include easingSharp(width, $enteringScreen);
    width: 27px;
    position: relative;
  }

  &__drawer-content {
    opacity: 0;
    @include easingSharp(opacity, $enteringScreen);
  }

  &__toggle {
    position: absolute;
    z-index: 1210;
    right: -18px;
    top: 32px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.08);

    &:hover {
      background-color: white !important;
    }
  }
}
