.user-item {
  &__avatar {
    display: flex;
    justify-content: center;
  }

  &__text {
    margin-left: 0px;
  }
}
