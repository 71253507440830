@import 'variables';

.quote-view {
  &__chat-drawer {
    width: 400px;
    z-index: 1601;
  }
}

@media only screen and (max-width: 900px) {
  .quote-view__chat-drawer {
    width: 100%;
  }
}
