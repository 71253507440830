.str-chat {
  overflow: hidden;

  .str-chat__input-flat {
    padding: var(--xs-p) var(--xl-p) !important;
  }

  .str-chat__list {
    .str-chat__reverse-infinite-scroll {
      padding-top: 0 !important;
      .str-chat__message-simple-text-inner,
      .str-chat__message-text-inner {
        border: none;
        border-radius: 0;
      }

      .str-chat__message--me .str-chat__message-text-inner,
      .str-chat__message-simple--me .str-chat__message-text-inner {
        background-color: white;
      }
    }
  }
}

.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  min-width: 100%;
}
