@import 'variables';

.layout-container {
  position: relative;
  width: 100%;
  height: calc(100vh - var(--app-header-height));
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-areas: 'sidebar content';
  align-items: start;
  overflow: hidden;

  &.no-header {
    height: 100vh;
  }
}
