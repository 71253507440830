.totals-summary-row {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgb(224, 224, 224);

  &.no-border {
    border: none;
  }
}
