@import 'variables';

.layout-header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: var(--app-header-height);
  display: flex;
  justify-content: center;
  z-index: $app-header-z-index;
  width: 100%;
}
