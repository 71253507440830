.quote-list-archived-section {
  background: none;

  &:before {
    content: none;
  }

  &__summary {
    display: inline-flex;
  }
}
