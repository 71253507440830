.checkout-success {
  &__button {
    font-size: 15px;
  }
  &__continue-shopping {
    display: flex;
    padding: 0.5em;

    border: 1px solid #7c0a22;
    border-radius: 0.25em;

    color: #7c0a22;
    text-decoration: none;
  }
}
