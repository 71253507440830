$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$screen-xm: 1024px;
$screen-xl: 1470px;
$screen-xl-min: $screen-xl;
$screen-xl-max: $screen-xl-min - 1;
$screen-xxl: 1700px;
$screen-xxl-min: $screen-xxl;
$screen-xxl-max: $screen-xxl-min - 1;
$app-header-z-index: 1200;

$app-content-max-width: 1600px;

:root {
  --app-header-height: 72px;
}

@media only screen and (max-width: 900px) {
  :root {
    --app-header-height: 56px;
  }
}
