$enteringScreen: 225ms;
$leavingScreen: 195ms;
$sharp: cubic-bezier(0.4, 0, 0.6, 1);
$easeInOut: cubic-bezier(0.4, 0, 0.2, 1);

@mixin easingSharp($property, $duration) {
  transition: $property $duration cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

@mixin easeInOut($property, $duration) {
  transition: $property $duration cubic-bezier(0, 0, 0.2, 1) 0ms;
}
