// Applies design layout on cell level (td) instead of row due to css limitations on table and row styling
.sales-quotes-table-row {
  &__message {
    &::before {
      content: '\A';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: green;
      display: inline-block;
      margin-right: 4px;
    }
  }
  & > td {
    line-height: unset;
    vertical-align: baseline;
    background-color: white;
    border: 1px solid #ebebeb;

    &:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: unset;
    }

    &:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-left: unset;
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-left: unset;
      border-right: unset;
    }
  }
}
