@import 'placeholders';

.quote-list-grid-item {
  @extend %quote-list-grid-item;
  cursor: pointer;

  &__message {
    &::before {
      content: '\A';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: green;
      display: inline-block;
      margin-right: 4px;
    }
  }
}
