.checkout-icon-base {
  padding: 1rem;
  border-radius: 50%;
  color: white;

  // Colors taken directly from Figma designs
  &.error {
    background-color: #f44336;
  }

  &.success {
    background-color: #4caf50;
  }

  &.pending-check {
    background-color: #ffb547;
  }

  &.pending-balance {
    background-color: #8e8e8e;
  }
  &.signature {
    background-color: rgba(130, 113, 110, 0.08);
  }
}
