@import 'variables';

%blended {
  mix-blend-mode: multiply;
}

%overlay {
  content: '';
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
