$margin: 12px;

.pdf-summary p,
.pdf-summary span,
.pdf-summary strong,
.pdf-summary h1,
.pdf-summary h2,
.pdf-summary h3,
.pdf-summary h4,
.pdf-summary h5,
.pdf-summary h6,
.pdf-table p,
.pdf-table span,
.pdf-table h6,
.pdf-table td,
.pdf-table th,
.pdf-payment-info p {
  font-size: 8px !important;
  line-height: 1.2;
}

.pdf-table {
  thead th,
  tbody td {
    padding: 4px 8px;
  }

  .quote-table__container {
    overflow-x: hidden;
  }

  .MuiTableCell-root {
    padding: 4px;
  }

  .MuiTableCell-root.quote-table-head-cell-contractNetPrice,
  .MuiTableCell-root.quote-table-head-cell-contractListPrice,
  .MuiTableCell-root.quote-table-head-cell-contractSubTotalPrice {
    min-width: 60px;
  }

  .quote-item-row__cell__leadTime p {
    text-align: left;
  }

  .quote-item-row__cell__price,
  .quote-item-row__cell__net-price,
  .quote-item-row__cell__sub-total {
    min-width: 40px;
    max-width: 80px;
  }

  .quote-item-row__cell__discount,
  .quote-item-row__cell__tax-rate {
    min-width: 60px;
    max-width: 60px;
  }

  .quote-item-row__cell__order {
    min-width: 40px;
    max-width: 40px;
  }

  .order-details-heading {
    font-size: 1rem;
  }
}

.pdf-payment-info {
  padding: 8px;
}

@media print {
  body {
    margin: 0;
    height: 100%;
  }

  .pdf-export-container {
    // page-break rules works only
    // if this container has a display: block
    display: block;
  }

  .pdf-divider {
    margin: 12px 0;
  }

  .pdf-table tr {
    break-inside: avoid;
  }

  .quote-item-row {
    break-inside: avoid;
  }

  .pdf-summary {
    margin-top: $margin;
    margin-left: auto;
  }

  .totals-summary {
    break-before: auto;
    break-inside: avoid;
  }

  .extra-payment-info {
    break-before: auto;
    break-inside: avoid;
  }

  .totals-summary-row {
    break-inside: avoid;
  }
}

@page {
  size: auto;
  margin: 14mm 14mm -2px;
}
