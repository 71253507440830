.filter-strip {
  &__chip {
    font-size: 13px;
  }
}

.filter-strip-skeleton {
  width: 120px;
  height: 32px;
  border-radius: 16px;
}
