// FIXME:Move it next to the loading screen component in separate pr since it will change on a lot of places
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loaded.loading {
  position: relative;
  overflow: hidden;

  &:before {
    @extend %overlay;
    z-index: 10;
    margin: 0;
  }

  &:after {
    z-index: 1039;
    top: 50% !important;
    left: 50% !important;
    margin: -2em auto 4em -2em;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 2px solid #666;
    border-right: 2px solid transparent;
    border-left: 2px solid #666;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 4em;
    height: 4em;
    display: block;
    content: '';
    position: absolute;

    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }

    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}

.loaded.loaded-small.loading {
  &.btn:before {
    background: rgba(80, 80, 80, 0.9);
  }

  &.btn-loading-no-bg:before {
    background: hsla(0, 0%, 100%, 0.8);
  }

  &:after {
    width: 2em;
    height: 2em;
    border-width: 2px;
    margin: -1em auto 1em -1em;
  }
}
